* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.navbar {
  width: 100%;
  height: 10vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  font-weight: bold;
}



.products-container {
  width: 100%;
  min-height: 80vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 10px;
}

img {
  width: 250px;
  height: 250px;
  transition: all 0.3s ease;
}

img:hover {
  transform: scale(1.1);
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
}

.card-description {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer {
  width: 100%;
  height: 10vh;
  background-color: black;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 19px;
  font-weight: bold;
}
